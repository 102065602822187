<template>
  <v-autocomplete
    v-model="chosenFacility"
    :items="items"
    item-value="id"
    item-title="name"
    :return-object="returnObject"
    :loading="loading"
    clear-on-select
    v-bind="$attrs"
  >
    <template #item="{ item, index, props }">
      <v-list-item
        v-bind="props"
        class="py-2"
      >
        <v-list-item-subtitle>
          {{ formatFacilityHierarchy(item.raw) }}
        </v-list-item-subtitle>
        <!--v-list-item-title>{{ item.raw.name }}</v-list-item-title-->
      </v-list-item>
      <v-divider
        v-if="index < facilities.length - 1"
        class="ma-0"
      />
    </template>
    <template #selection="{ item }">
      <v-list-item class="pa-0">
        <v-list-item-subtitle>
          {{ formatFacilityHierarchy(item.raw) }}
          {{ item.raw.parent_facility ? ' >' : '' }}
        </v-list-item-subtitle>
        <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Object],
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: null,
    },
    sort: {
      type: Array,
      default: () => ['name'],
    },
    selectFirst: {
      type: Boolean,
    },
    returnObject: {
      type: Boolean,
    },
    additionalItems: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      facilities: [],
      loading: false,
    }
  },
  computed: {
    items() {
      return [...this.facilities, ...this.additionalItems]
    },
    chosenFacility: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  watch: {
    filter: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const requestConfig = {
        fields: [
          'id',
          'name',
          'show_in_hierarchy',
          'parent_facility.id',
          'parent_facility.name',
          'parent_facility.show_in_hierarchy',
          'parent_facility.parent_facility.id',
          'parent_facility.parent_facility.name',
          'parent_facility.parent_facility.show_in_hierarchy',
          'parent_facility.parent_facility.parent_facility.id',
          'parent_facility.parent_facility.parent_facility.name',
          'parent_facility.parent_facility.parent_facility.show_in_hierarchy',
          'parent_facility.parent_facility.parent_facility.parent_facility.id',
          'parent_facility.parent_facility.parent_facility.parent_facility.name',
          'parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
          'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.id',
          'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.name',
          'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
          ...this.fields,
        ],
      }
      if (this.filter) {
        requestConfig.filter = this.filter
      }
      const facilityResponse = await this.$cms.request(this.$readItems('facility', requestConfig))
      this.facilities = facilityResponse
      if (this.selectFirst && this.facilities.length) {
        if (this.returnObject) {
          this.chosenFacility = this.facilities[0]
        } else {
          this.chosenFacility = this.facilities[0].id
        }
      }
      this.loading = false
    },
  },
}
</script>
